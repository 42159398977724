<template>
    <div class="photo-wrapper" @click.stop="onClickImage">
        <img v-if="imageUrl" class="img-cover flex-wrap" v-lazy="imageUrl" />
        <div v-if="user.name" class="user-profile">
            <div class="detail">
                <div class="f-medium f-14 m-b-4" v-html="nicknameAge" />
                <div class="region" v-html="region" />
            </div>
            <button class="profile-btn" @click.stop="$emit('seeProfile')">
                <i class="material-icons">account_box</i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhotoLayout',
    props: ['user', 'image'],
    computed: {
        nicknameAge() {
            return `${this.user.nickname || '-'}, ${this.$options.filters.asAge(this.user.birthday)}`
        },
        region() {
            return this.$options.filters.noDetail(this.user.region) || '-'
        },
        imageUrl() {
            return this.user.show_photo === false ? this.$blankProfile : this.image
        },
    },
    methods: {
        onClickImage() {
            if (this.user.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }
            this.$modal.images({
                images: [this.image],
            })
        },
    },
}
</script>
