<template>
    <div class="multi-photo-layout">
        <div class="single-photo" v-if="numImages === 1">
            <img
                class="img-profile flex-wrap"
                :src="images[0]"
                @click="$emit('click', images[0])"
                @error="onErrorOneImage">
            <div class="profile-btn" @click.stop="$emit('seeProfile')">
                <i class="material-icons">account_box</i>
            </div>
        </div>
        <div
            v-else
            class="card">
            <div
                v-if="rowOne.length > 0"
                class="grid-row-1"
                :class="{
                    'two': rowOne.length === 2,
                    'three': rowOne.length === 3,
                }">
                <div
                    @click="$emit('click', url)"
                    :key="url"
                    v-for="url in rowOne"
                    v-img-cover="url"/>
            </div>
            <div
                v-if="rowTwo.length > 0"
                class="grid-row-2"
                :class="{
                    'two': rowTwo.length === 2,
                    'three': rowTwo.length === 3,
                }">
                <div
                    @click="$emit('click', url)"
                    :key="url"
                    v-for="url in rowTwo"
                    v-img-cover="url"/>
            </div>
            <div class="profile-btn" @click.stop="$emit('seeProfile')">
                <i class="material-icons">account_box</i>
            </div>
        </div>
        <div class="source-info-with-timestamp">
            <div class="flex-row items-end">
                <div class="source-info hor-separated" v-if="user.source_id">
                    <span><i class="material-icons">account_box</i>{{ user.birthday | asAge }}세</span>
                    <span>{{ user.height }}cm</span>
                    <span>{{ user.region }}</span>
                    <span>{{ user.job }}</span>
                </div>
                <div class="timestamp">{{ message.created_at | formatDate("A h:mm") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MultiPhotoLayout',
    props: ['message', 'user'],
    data: () => ({
        rowOne: [],
        rowTwo: [],
    }),
    watch: {
        images() {
            this.init()
        },
    },
    computed: {
        images() {
            if (this.content.show_photo === false) return [this.$blankProfile]
            else return (this.content || {}).urls
        },
        numImages() {
            return (this.images || []).length
        },
        content() {
            return this.$mustParse(this.message.content)
        },
    },
    methods: {
        onErrorOneImage() {
            this.images[0] = this.$blankProfile
        },
        init() {
            const len = (this.images || []).length
            try {
                if (len === 4) {
                    this.rowOne = this.images.slice(0, 2)
                    this.rowTwo = this.images.slice(2, 4)
                } else {
                    const pivot = len > 3 ? 3 : len
                    this.rowOne = this.images.slice(0, pivot)
                    this.rowTwo = this.images.slice(pivot, 6)
                }
            } catch (e) {}
        },
        onClickMultiPhotoLayout() {
            this.$modal.images({
                images: this.images,
            })
        },
    },
    mounted() {
        this.init()
    },
}
</script>
